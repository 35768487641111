import { useState, useEffect } from "react";
import useSWR from "swr";
import { Table, Text, Loader, Paper, Pagination, Flex} from "@mantine/core";
import { fetcher } from "../../../../services/fetcher";
import {
  errorNotifications,
  // successNotifications,
} from "../../../../utils/handleNotifications";
import classes from "./EmailLogs.module.css";

const emailLogsEndpoint = "bulk-email/email-logs/";

const EmailLogs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, error, isLoading, mutate } = useSWR(
    `${emailLogsEndpoint}?page=${currentPage}`,
    (url) => fetcher(url, "GET"),
    { revalidateOnFocus: false } // Optional: Prevent revalidation on focus
  );

  // Show success notification when data is successfully loaded
  // useEffect(() => {
  //   if (data && !isLoading) {
  //     successNotifications("Data Loaded", `Page ${currentPage} loaded successfully.`);
  //   }
  // }, [data, isLoading, currentPage]);

  // Show error notification
  useEffect(() => {
    if (error) {
      errorNotifications("Error Loading Data", error.message || "Failed to fetch email logs.");
    }
  }, [error]);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Loader size="lg" />
        <Text>Loading Email Logs...</Text>
      </div>
    );
  }

  if (!data?.results || data.results.length === 0) {
    return (
      <Text align="center" style={{ marginTop: "20px" }}>
        No email logs available.
      </Text>
    );
  }

  const hasNextPage = data.links.next !== null;

  return (
    <Paper shadow="md" className={classes.section}>
      <Table striped highlightOnHover withBorder withColumnBorders>
        <thead>
          <tr>
            <th className={classes.heading}>ID</th>
            <th className={classes.heading}>Recipient</th>
            <th className={classes.heading}>Subject</th>
            <th className={classes.heading}>Status</th>
            <th className={classes.heading}>Sent At</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map((log) => (
            <tr key={log.id}>
              <td className={classes.content}>{log.id}</td>
              <td className={classes.content}>{log.recipient}</td>
              <td className={classes.content}>{log.subject}</td>
              <td
                style={{
                  color: log.status === "success" ? "green" : "red",
                }}
              >
                {log.status}
              </td>
              <td className={classes.content}>
                {new Date(log.sent_at).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Flex justify="end" mt="lg">
        <Pagination
          total={hasNextPage ? currentPage + 1 : currentPage}
          page={currentPage}
          onChange={(page) => {
            if (page !== currentPage) {
              setCurrentPage(page);
              mutate(); // Revalidate SWR when page changes
            }
          }}
          position="center"
          mt="md"
          withEdges
        />
      </Flex>
    </Paper>
  );
};

export default EmailLogs;