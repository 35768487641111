import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { TextInput, Textarea, Button, Group, Paper, Flex, FileInput, Anchor, Title, Text, Table } from "@mantine/core";
import { fetcher } from "../../../../services/fetcher";
import { errorNotifications, successNotifications } from "../../../../utils/handleNotifications";
import classes from "./EditTemplate.module.css";

const EditTemplate = () => {
  const csvSectionRef = useRef(null);
  const { id } = useParams();
  // const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ subject: "", body: "" });
  const [attachment, setAttachment] = useState(null);
  const [csvFile, setCsvFile] = useState(null); // State for CSV file
  const [csvData, setCsvData] = useState([]);
  const [existingAttachment, setExistingAttachment] = useState("");
  const [isStaticTemplate, setIsStaticTemplate] = useState(false);
  const [showCsvSection, setShowCsvSection] = useState(false); // State to show/hide CSV section
//   const [modalOpened, setModalOpened] = useState(false);
//   const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const data = await fetcher(`bulk-email/email-templates/?id=${id}`);
        setFormValues({ subject: data.subject, body: data.body });

        if (data.template_type === "static") {
          setIsStaticTemplate(true);
        }

        if (data.attachment) {
          setExistingAttachment(data.attachment);
        }
      } catch (error) {
        // console.error("Failed to fetch template:", error);
        errorNotifications("Failed to fetch template", error);
      }
    };
    fetchTemplate();
  }, [id]);

  const handleInputChange = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleTemplateSubmit = async () => {
    const formData = new FormData();
    formData.append("subject", formValues.subject);
    formData.append("body", formValues.body);

    if (attachment) {
      formData.append("attachment", attachment);
    }

    try {
      await fetcher(`bulk-email/email-templates/?id=${id}`, "PATCH", formData, {
        "Content-Type": "multipart/form-data",
      });
      successNotifications("Template updated successfully!");
      setShowCsvSection(true); // Show the CSV section after successful update

    // Scroll to CSV section after a short delay
    setTimeout(() => {
      csvSectionRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 300);
    } catch (error) {
    //   console.error("Failed to update template:", error);
    errorNotifications("Failed to update template", error);
 
    }
  };

  const handleCsvUpload = async () => {
    if (!csvFile) {
      alert("Please upload a CSV file.");
      return;
    }

    const formData = new FormData();
    formData.append("template_id", id);
    formData.append("csv_file", csvFile);

    try {
      const response = await fetcher("bulk-email/send-bulk-emails/", "POST", formData, {
        "Content-Type": "multipart/form-data",
      });
      successNotifications(response.message);
    //   setModalOpened(true);
    } catch (error) {
      console.error("Failed to send bulk emails:", error);
    }
  };

  const handleCsvFileChange = (file) => {
    setCsvFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      const rows = text.split("\n").map((row) => row.split(","));
      setCsvData(rows);
    };
    reader.readAsText(file);
  };

  return (
    <>
      <Paper shadow="md" className={classes.section}>
        <div style={{ padding: "36px 0px 36px 0px", maxWidth: "600px", margin: "auto" }}>
          <Flex direction="column" gap="16px">
            {/* Subject Field */}
            <Flex align="center" gap="2rem">
              <label style={{ flex: 1 }} className={classes.heading}>
                Subject
              </label>
              <TextInput
                value={formValues.subject}
                onChange={(e) => handleInputChange("subject", e.target.value)}
                styles={{
                  input: {
                    border: "none",
                    borderRadius: "8px",
                    background: "#F6F6F6",
                  },
                }}
                style={{ flex: 2 }}
              />
            </Flex>

            {/* Body Field */}
            <Flex gap="2rem">
              <label style={{ flex: 1 }} className={classes.heading}>
                Body
              </label>
              <Textarea
                value={formValues.body}
                onChange={(e) => handleInputChange("body", e.target.value)}
                minRows={4}
                disabled={isStaticTemplate}
                styles={{
                  input: {
                    border: "none",
                    borderRadius: "8px",
                    background: "#F6F6F6",
                    height: "248px",
                  },
                }}
                style={{ flex: 2 }}
              />
            </Flex>

            {/* Attachment Field */}
            <Flex direction="column" gap="0.5rem">
              <Flex align="center" gap="2rem">
                <label style={{ flex: 1 }} className={classes.heading}>
                  Attachment
                </label>
                <FileInput
                  placeholder="Upload file"
                  onChange={setAttachment}
                  styles={{
                    input: {
                      border: "none",
                      borderRadius: "8px",
                      background: "#F6F6F6",
                    },
                  }}
                  style={{ flex: 2 }}
                />
              </Flex>

              {existingAttachment && (
                <div style={{ marginLeft: "14rem", marginTop: "0.5rem" }}>
                  <Anchor
                    href={existingAttachment}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="blue"
                    underline
                  >
                    {existingAttachment.split("/").pop()}
                  </Anchor>
                </div>
              )}
            </Flex>
          </Flex>

          <Group justify="center" style={{ marginTop: "4rem" }}>
            <Button onClick={handleTemplateSubmit}>Confirm</Button>
          </Group>
        </div>
      </Paper>

      {/* CSV Upload Section */}
      {showCsvSection && (
        <Paper ref={csvSectionRef} shadow="md" mt="lg" className={classes.section}>
          <div className={classes.csvsection}>
            <Title className={classes.head}>Upload CSV File</Title>
            <FileInput
              placeholder="Drag and drop or Click here to Upload"
              onChange={handleCsvFileChange}
              accept=".csv"
              classNames={{
                input: classes.fileInput,
                placeholder: classes.placeholder,
              }}
            />
            <Text className={classes.note}>
              <span className={classes.noteSection}>Note:</span> Please check your Document before uploading
            </Text>
            {csvData.length > 0 && (
              <div style={{ maxHeight: csvData.length > 10 ? "400px" : "auto", overflowY: csvData.length > 10 ? "auto" : "visible"}}>
              <Table mt="lg" striped withBorder style={{ border: "1px solid #ccc", padding: "8px" }}>
                <thead>
                  <tr>
                    {csvData[0].map((header, i) => (
                      <th style={{ textAlign: "left",  border: "1px solid #ccc", padding: "8px"  }} key={i}>
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {csvData.slice(1).map((row, i) => (
                    <tr key={i}>
                      {row.map((cell, j) => (
                        <td style={{ border: "1px solid #ccc", padding: "8px" }} key={j}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            )}
            <Group justify="center" mt="xl">
              <Button onClick={handleCsvUpload}>View and Confirm</Button>
            </Group>
          </div>
        </Paper>
      )}
      {/* <Modal 
        opened={modalOpened} 
        onClose={() => setModalOpened(false)} 
        title="Bulk Email Status" 
        centered 
        className={classes.modal}
        closeButtonProps={{
          icon: (
            <img
              src="/images/svg/close.svg"
              style={{ width: "1.6rem" }}
              alt="close-icon"
            />
          ),
        }}
      >
        <Text className={classes.responseMsg}>{responseMessage}</Text>
      </Modal> */}
    </>
  );
};

export default EditTemplate;