import { Outlet } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../../services/fetcher";

const bulkemailEndpoint = "bulk-email/email-templates/";

const BulkMailLayout = () => {
  const { data, error, isLoading } = useSWR(bulkemailEndpoint, fetcher);

  return <Outlet context={{ data, error, isLoading }} />;
};

export default BulkMailLayout;